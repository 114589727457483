/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { SaveButton, CustomCheckbox, CheckMark, ButtonContainer, InviteContainer, InviteAmigo,
  NameContainer, ImageInput, ImageContentContainer, InputContainer, StartDateContainer, DateJoiner, CustomMenuItem, StyledDateTime,
  TitleContainer, FieldTitle, ImageContainer, AmigosContainer, TopContainer, ScrollableContainer, Layout, PageTitle, FormContainer,
  StyledInput,CustomDropDown, PlacesContainer, DateIconContainer, RemovePhotoButton, LocationContainer, TimezoneContainer, EventContainer } from '../CreateEvent/styles';
import { FeaturedEventsContainer } from '../CreateChallenge/styles';
import { RadioButtonContainer } from '../CreateRecipe/styles';
import { ActivityDropdown, ActivityTypeContainer } from '../CreateChallengeForm/styles';
import RadioButton from "../CustomRadioButton";
import FeaturedEvents from '../FeaturedEvents';
import Waiting from '../Waiting';
import { getOrientation, resetOrientation, convertDateInTimezone, checkImage, getPermissionStatus } from '../../utils/methods';
import { ImageUrl, imageErrorMessage, TimeZone } from '../../utils/constants';
import { /*repeatValues,*/ /*eventsTierPoints,*/ /*recurringTypes*/} from '../../../mockData.json';
import { showEmailNotificationInfo, /* repeatValues, */ /* eventsTierPoints, */ /* recurringTypes, */} from '../../../mockData';
import {getAllCompanies} from "../../redux/actions/challengeActions";
import {getUserCompanyDetails} from "../../redux/actions/userProfileActions";
import { EditEventAction } from '../../redux/actions';
import Editor from "../CKEditor";
import {EditorContainer} from "../EditWellnessJourney/styles";
import Autocomplete from "../Autocomplete";
import timezones from "../../../timezones.json";
import LazyImage from '../common/LazyImage/LazyImage';

class EditEvent extends Component {
  constructor(props) {
    super();
    let updatedTiers = eventsTierPoints;
    props.role === 'WELLNESS_CHAMPION' && updatedTiers.splice(3);
    this.state = {
      imgSrc: '',
      title: '',
      date: moment(),
      endDate: moment(),
      location: '',
      description: '',
      imageName: '',
      time: moment().format('hh:mm a'),
      endTime: moment().format('hh:mm a'),
      showDateTimeInput: '',
      selectedMenu: 'Select an option',
      showPlaces: true,
      amigosToInvite: [],
      openDateTimePicker: '',
      eventLink: '',
      companies: [],
      com_location: [],
      eventPointValue: '',
      limitUser: '0',
      addLimitCheckbox: false,
      isSpouse: false,
      isDependent: false,
      isEmployee: false,
      selectedOption: 0,
      eventPointKey: 'Tier 1: 10 points',
      emailOption: {
        "launch_event_email": 0,
        "event_reminder_email": 0,
        "event_complete_email": 0
      },
      eventPoints: updatedTiers,
      time_zone: TimeZone === "Asia/Calcutta" ? "Asia/Kolkata" : TimeZone,
      companiesPermission: false,
      locationPermission: false,
      recurringEvent: this.recurringTypes[0],
      eventLimitValue: 0
    };
  }

  repeatValues= [
    "Repeat Every Day",
    "Repeat once",
    "Repeat Whole Week"
  ]

  recurringTypes= [
    "does not repeat",
    "weekly"
  ]

  eventsTierPoints=[
    {"key": "Tier 1: 10 points", "value": 10},
    {"key": "Tier 2: 25 points", "value": 25},
    {"key": "Tier 3: 50 points", "value": 50},
    {"key": "Tier 4: 100 points", "value": 100},
    {"key": "Tier 5: 250 points", "value": 250},
    {"key": "Tier 6: 500 points", "value": 500}
  ]

  componentDidMount() {
    const companiesPermission = getPermissionStatus("Show companies in event", this.props.userPermissions);
    const locationPermission = getPermissionStatus("Show locations in event", this.props.userPermissions);
    if(companiesPermission) {
      if(!this.props.allCompanies){
        this.props.fetchAllCompanies();
      }
    } else {
      this.props.fetchCompanyForUser();
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id]
      })
    }
    document.addEventListener('mousedown', this.handleClick);

    const { history, eventDetail } = this.props;
    const { pathname } = history.location;
    const arr = pathname.split('/');
    if(!eventDetail || _.isEmpty(eventDetail)) {
      history.push(`/events/${arr[arr.length-2]}`);
    } 
    else {
      let TierPointsIndex = this.eventsTierPoints.findIndex((data) => data.value == _.get(eventDetail, 'event_points', 10));
      const eventTimezone = eventDetail.time_zone ? eventDetail.time_zone : (TimeZone === "Asia/Calcutta" ? "Asia/Kolkata" : TimeZone)
      this.setState({
        eventId: eventDetail.id,
        imgSrc: eventDetail.image,
        title: eventDetail.title,
        date: moment.utc(eventDetail.start_date).tz(eventTimezone).format('MM/DD/YYYY'),
        endDate: moment.utc(eventDetail.end_date).tz(eventTimezone).format('MM/DD/YYYY'),
        location: eventDetail.event_location || '',
        description: eventDetail.body,
        imageName: eventDetail.image,
        time: moment.utc(eventDetail.start_date).tz(eventTimezone).format('hh:mm a'),
        endTime: moment.utc(eventDetail.end_date).tz(eventTimezone).format('hh:mm a'),
        eventLink: eventDetail.event_link || '',
        selectedOption: eventDetail.event_location ? 0 : 1,
        companies: eventDetail.event_companies.split(',').map((value) => parseInt(value,10)),
        eventPointValue: TierPointsIndex === -1 ? 10 : eventDetail.event_points,
        apiChecker: (convertDateInTimezone(eventDetail.start_date)._d < moment()._d && convertDateInTimezone(eventDetail.end_date)._d >= moment()._d) ? true : false,
        com_location: eventDetail.event_city_states ? eventDetail.event_city_states.split(',').map((value) => (
          parseInt(value,10)
        )) : [],
        isSpouse: eventDetail.spouse,
        isDependent: eventDetail.dependent,
        isEmployee: false || !eventDetail.is_spouse_or_dependent,
        eventPointKey: this.eventsTierPoints[TierPointsIndex === -1 ? 0 : TierPointsIndex].key,
        time_zone: eventTimezone,
        emailOption: {
          "launch_event_email": eventDetail.launch_event_email,
          "event_reminder_email": eventDetail.event_reminder_email,
          "event_complete_email": eventDetail.event_complete_email
        },
        recurringEvent: eventDetail.is_recurring_event ? eventDetail.recurring_type : this.recurringTypes[0],
        eventLimitValue: eventDetail.recurring_event_limit
      })
      if(eventDetail.user_limit) {
        this.setState({
          addLimitCheckbox: true,
          limitUser: eventDetail.user_limit
        })
      }
    }
    this.setState({
      companiesPermission: companiesPermission,
      locationPermission: locationPermission
    })
  }

  changeLimitCheckBox = () => {
    this.setState({
      addLimitCheckbox: !this.state.addLimitCheckbox
    })
  }
  
  checkBase64(image) {
    if(image) {
      let arr = image.split('/');
      if(arr[0] !== 'event-image') {
        return true;
      }
      else {
        return false;
      }
    }
  }

  componentDidUpdate(prevProps) {
    if(!this.state.companiesPermission && this.props.userCompany !== prevProps.userCompany) {
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id]
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  onChangeLocation = (id) => {
    const { com_location } = this.state;
    
    if(com_location.includes(id)) {
      let index = com_location.findIndex((item) => item === id);
      if(index > -1) {
        com_location.splice(index, 1);
      }
    } else {
      com_location.push(id);
    }
    this.setState({
      com_location: com_location
    })
  }

  onChangeTimeZone = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  selectAllLocation = (e, locationDetails) => {
    e.preventDefault();
    const { com_location } = this.state;
    if(com_location.length == locationDetails.length) {
      this.setState({
        com_location: []
      })
    }
    else {
      let arr = [];
      for(let i=0; i< locationDetails.length; i++) {
        arr.push(locationDetails[i].id);
      }
      this.setState({
        com_location: arr
      })
    }
  }

  handleClick = (e) => {
    if(this.datePickerWrapper && !this.datePickerWrapper.contains(e.target)) {
      this.setState({
        openDateTimePicker: ''
      });
    }
  };

  setDatePickerWrapper = (node) =>  {
    if(!this.state.apiChecker) {
      this.datePickerWrapper = node;
    }
  };

  onChange = (e) => {
    let file = e.target.files[0];
    if(checkImage(e.target.files)) {
      if((file?.size/1000000) <= 20){
        let reader = new FileReader();
        reader.readAsDataURL(file);
        const array = document.getElementById('edit-event-file').value.split("\\");
        reader.onloadend = () => {
          this.setState({
            imgSrc: reader.result,
            imageName: array[array.length - 1],
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrc: baseImage
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById('edit-event-file').value = ''; 
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('edit-event-file').value = '';
    }
  };

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  removePhoto = () => {
    document.getElementById('edit-event-file').value = '';
    this.setState({
      imgSrc: '',
      imageName: '',
    });
  };

  changeDateTimeView = (value) => {
    if(this.state.showDateTimeInput === '') {
      this.setState({
        date: moment(moment().add(1, 'days')._d).format('MM/DD/YYYY'),
        endDate: moment(moment().add(1, 'days')._d).format('MM/DD/YYYY'),
        time: '12:00 am',
        endTime: '11:59 pm',
        showDateTimeInput: value
      })
    } else {
      this.setState({
        date: moment(moment()._d).format('MM/DD/YYYY'),
        endDate: moment(moment()._d).format('MM/DD/YYYY'),
        time: moment().format('hh:mm a'),
        endTime: moment().format('hh:mm a'),
        showDateTimeInput: '',
      });
    }
  };

  onSelect = (value) => {
    this.setState({
      selectedMenu: value,
    });
  };

  onSelectPoints = (name, value, key) => {
    this.setState({
      [name]: value,
      eventPointKey: key
    });
  };

  changeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if(showDateTimeInput === 'date') {
      this.setState({
        date: moment(e._d).format('MM/DD/YYYY'),
        time: moment(e._d).format('hh:mm a'),
        endDate: moment(e._d).format('MM/DD/YYYY'),
        endTime: '11:59 pm',
        openDateTimePicker: '',
      });
    } else if(stateName.includes('ate')) {
      this.setState({
        openDateTimePicker: '',
        [stateName]: moment(e._d).format('MM/DD/YYYY'),
      });
    } else {
      this.setState({
        [stateName]: moment(e._d).format('hh:mm a'),
        openDateTimePicker: stateName
      });
    }
  };

  showDatePicker = (value) => {
    if(this.state.openDateTimePicker === value) {
      this.setState({
        openDateTimePicker: ''
      });
    } else {
      this.setState({
        openDateTimePicker: value
      });
    }
  };

  onCheckboxChange = (element) => {
    const { companies } = this.state;
    if(companies.includes(element)) {
      let index = companies.findIndex((item) => item === element);
      if(index > -1) {
        companies.splice(index, 1);
      }
    } else {
      companies.push(element);
    }
    this.setState({
      companies: companies
    })
  };
  onChangeEmail=(key)=>{
    const {emailOption} = this.state;
    emailOption[key] = emailOption[key] === 0 ? 1 : 0;
    this.setState({emailOption: emailOption});
  }

  selectAllCompanies = (e) => {
    e.preventDefault();
    let invitedCompanies = [];
    const { allCompanies } = this.props;
    if(this.state.companies.length === allCompanies.length) {
      invitedCompanies = [];
    } else {
      allCompanies.map((company) => {
        invitedCompanies.push(company.id);
      });
    }
    this.setState({
      companies: invitedCompanies
    })
  };

  onLocationChange = (e) => {
    const { getPlacesAction } = this.props;
    this.setState({
      location: e.target.value,
      showPlaces: true
    }, () => {
      if(this.state.location.length > 3 && this.state.location.trim() != '') getPlacesAction(this.state.location.trim());
    });
  };

  setLocation = (location) => {
    this.setState({
      location: location,
      showPlaces: false
    });
  };

  renderPlaces = (places) => places.map((place, index) => (
    <div key={index} onClick={() => this.setLocation(place.description)}>
      <span>{place.description}</span>
    </div>
  ));

  saveEvent = (e) => {
    e.preventDefault();
    const { apiChecker, com_location, eventId, title, date, description, imgSrc, time, endDate, endTime, location,
      eventLink, companies, eventPointValue, addLimitCheckbox, limitUser, selectedOption, time_zone, emailOption, locationPermission, recurringEvent, eventLimitValue} = this.state;
    const { EditEventAction, companyDetails} = this.props;
    let isRecurringEvent = recurringEvent === this.recurringTypes[0] ? 0 : 1;
    const EventDetails = {
      title,
      companies: companies,
      body: description,
      image : imgSrc,
      end_date: moment(endDate).format('YYYY-MM-DD') + ' ' + moment(endTime, "hh:mm a").format("HH:mm:ss"),
      start_date: moment(date).format('YYYY-MM-DD') +  ' ' + moment(time, "hh:mm a").format("HH:mm:ss"),
      event_location: !selectedOption ? location : null,
      event_link: selectedOption ? eventLink : null,
      id: eventId,
      event_points: eventPointValue,
      imageUpdated: this.checkBase64(imgSrc) ? 1 : 0,
      time_zone: time_zone,
      ...emailOption,
      is_recurring_event : isRecurringEvent,
      recurring_type: isRecurringEvent ? recurringEvent : null,
      recurring_event_limit: isRecurringEvent ? eventLimitValue : 0
    };

    if(locationPermission) {
      EventDetails.company_city_states = com_location;
      EventDetails.event_for_spouse = this.state.isSpouse;
      EventDetails.event_for_dependent = this.state.isDependent;
      if(this.props.locationDetails && this.props.locationDetails.length > 0) {
        if(com_location && com_location.length > 0){
          EventDetails.is_for_employee = 1
        }
        else{
          EventDetails.is_for_employee = 0
        }
      }
      else {
        if(companyDetails['spouse_or_dependent']) {
          EventDetails.is_for_employee = this.state.isEmployee ?  1 : 0
        }
        else{
          EventDetails.is_for_employee = 1
        }
      }
    }

    if(apiChecker) {
      EventDetails.is_event_started_or_over = 1;
    }
    if(addLimitCheckbox){
      EventDetails.user_limit = limitUser;
    }
    EditEventAction(EventDetails);
  };

  onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, '');
    trimmedString.length  === 0 || trimmedString.length === 7 || trimmedString === '<p></p><p></p>'  ?  this.setState({description: ""}) : this.setState({description: newContent})
  };

  toggleRadioButton = (e) => {
    this.setState({selectedOption: parseInt(e.target.value,10)}, ()=>{});
  };

  render() {
    const { title, location, description, imageName, imgSrc, showDateTimeInput, selectedMenu, date, endDate, time, endTime,
      showPlaces, openDateTimePicker, companies, eventLink, com_location, apiChecker, eventPointValue,
      addLimitCheckbox, limitUser, isSpouse, isDependent, isEmployee, selectedOption, eventPointKey, eventPoints, time_zone, emailOption, companiesPermission, locationPermission, recurringEvent, eventLimitValue} = this.state;
    const { places, isLoading, allCompanies, userCompany, locationDetails, history, companyDetails} = this.props;
    const MandatoryFields = ['title', 'imgSrc', 'description', 'companies'];
    if((companiesPermission && !allCompanies) || (locationPermission && !userCompany) || isLoading) {
      return <Waiting />
    }
    let isDisabled = false;
    let emailOptionKeys = Object.keys(emailOption);
    const disabledEmailOptionStatus = true;
    MandatoryFields.map((field) => {
      if(!this.state[field] || _.isEmpty(companies) || !this.state.title.trim() || !this.state.description.trim()) {
        isDisabled = true;
      }
    });

    if(addLimitCheckbox && (limitUser== '' || limitUser == '0')) {
      isDisabled = true;
    }

    if((!selectedOption && !location.trim()) || (selectedOption  && !eventLink.trim())) {
      isDisabled = true;
    }

    if(locationPermission) {
      if(locationDetails && locationDetails.length > 0 && com_location.length < 1 && !(this.state.isSpouse || this.state.isDependent)) {
        isDisabled = true;
      }
      if(locationDetails && locationDetails.length === 0) {
        if(!(this.state.isSpouse || this.state.isDependent || this.state.isEmployee)){
          isDisabled = true;
        }
      }
    } 

    const yesterday = Datetime.moment().subtract(23, 'hour');
    const valid = (currentDate) => showDateTimeInput === 'date' ? currentDate.isAfter(Datetime.moment()) : currentDate.isAfter(yesterday);
    
    const disabledFunctions = apiChecker;
    const eventWeeklyLimits = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const eventLimits = eventWeeklyLimits;
    return (
      <Layout>
        <PageTitle>Edit Event</PageTitle>
        <EventContainer>
          <FormContainer onSubmit={this.saveEvent}>
            <TitleContainer>
              <FieldTitle>Event Title:</FieldTitle>
              <StyledInput
                placeholder="Type title here..."
                name="title"
                onChange={this.onChangeInput}
                value={title}
                maxLength="100"
                disabled={disabledFunctions}
              />
            </TitleContainer>
            <ImageContainer>
              <FieldTitle>Event Picture:</FieldTitle>
              <div>
                { imageName &&
                  <div>
                    <img src={this.checkBase64(imgSrc) ? imgSrc : `${ImageUrl}/${imgSrc}`}/>
                    <ImageContentContainer>
                      <div>{imageName}</div>
                      <RemovePhotoButton color="#aaaaaa" type="button" onClick={this.removePhoto} disabled={disabledFunctions}>Remove photo</RemovePhotoButton>
                    </ImageContentContainer>
                  </div>
                }
                <ImageInput htmlFor="edit-event-file" showAtBottom={imageName !== ''} disabled={disabledFunctions}>
                  {imageName ? 'browse new' : 'browse'}
                  <input
                    id="edit-event-file"
                    type="file"
                    name="user"
                    accept=".jpeg, .png, .jpg"
                    multiple={false}
                    onChange={(e) => this.onChange(e)}
                    onClick={(e) => e.target.files[0] && this.onChange(e)}
                  />
                </ImageInput>
              </div>
            </ImageContainer>
            <TitleContainer>
              <ActivityTypeContainer>
                <FieldTitle>Event Point Value:</FieldTitle>
                <ActivityDropdown
                  title={eventPointKey}
                  id="dropdown-basic"
                  disabled={disabledFunctions}
                >
                  {
                    eventPoints.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onSelect={() => this.onSelectPoints('eventPointValue', item['value'], item['key'])}
                        active={eventPointValue === item['value']}
                      >
                        {item['key']}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </ActivityTypeContainer>
            </TitleContainer>
            <TitleContainer>
              <ActivityTypeContainer>
                <FieldTitle>Event Limit Users:</FieldTitle>
                <div>
                  <CustomCheckbox> Add limit
                    <input type="checkbox" checked={addLimitCheckbox} onChange={() => !disabledFunctions && this.changeLimitCheckBox()}/>
                    <CheckMark checked={addLimitCheckbox} />
                  </CustomCheckbox>
                </div>
                <StyledInput
                  type={addLimitCheckbox ? 'number': 'text' }
                  name="limitUser"
                  onChange={this.onChangeInput}
                  value={ addLimitCheckbox ? limitUser : 'All user'}
                  min="1"
                  pattern='[0-9]*'
                  disabled={!addLimitCheckbox || disabledFunctions}
                />
              </ActivityTypeContainer>
            </TitleContainer>
            <ImageContainer>
              <ActivityTypeContainer>
                <FieldTitle>Frequency:</FieldTitle>
                <ActivityDropdown
                  title={recurringEvent}
                  id="dropdown-recurring"
                  disabled={disabledEmailOptionStatus}
                >
                  {
                    this.recurringTypes.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onSelect={() => this.onChangeTimeZone('recurringEvent', item)}
                        active={recurringEvent === item}
                      >
                        {item}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </ActivityTypeContainer>
              {recurringEvent !== this.recurringTypes[0] && <ActivityTypeContainer>
                <FieldTitle>{`Number Of ${recurringEvent === this.recurringTypes[1] ? "Weeks" : 'Months'}`}:</FieldTitle>
                <ActivityDropdown
                  title={`${eventLimitValue} ${recurringEvent === this.recurringTypes[1] ? "Weeks" : 'Months'}`}
                  id="dropdown-limit"
                  disabled={disabledEmailOptionStatus}
                >
                  {
                    eventLimits.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onSelect={() => this.onChangeTimeZone('eventLimitValue', item)}
                        active={eventLimitValue === item}
                      >
                        {`${item} ${recurringEvent === this.recurringTypes[1] ? "weeks" : 'Months'}`}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </ActivityTypeContainer>}
            </ImageContainer>
            <TitleContainer addMargin>
              <FieldTitle>Event Date:</FieldTitle>
              <div>
                <CustomCheckbox disabled={disabledFunctions}>All Day
                  <input type="checkbox" checked={showDateTimeInput === 'date'} onChange={() => !disabledFunctions &&this.changeDateTimeView('date')}/>
                  <CheckMark checked={showDateTimeInput === 'date'} />
                </CustomCheckbox>
                <CustomCheckbox hidden>Repeat Event
                  <input type="checkbox" checked={showDateTimeInput === 'repeat'} onChange={() => this.changeDateTimeView('repeat')} />
                  <CheckMark checked={showDateTimeInput === 'repeat'} />
                </CustomCheckbox>
              </div>
              {showDateTimeInput === 'date' ?
                <InputContainer disabled={disabledFunctions}>
                  <div ref={(node) => !disabledFunctions && openDateTimePicker === 'date' && this.setDatePickerWrapper(node)}>
                    <StyledDateTime
                      open={openDateTimePicker === 'date'}
                      inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true, disabled:disabledFunctions}}
                      dateFormat="MM/DD/YYYY"
                      closeOnSelect={true}
                      closeOnTab={true}
                      timeFormat={false}
                      value={date}
                      onChange={(e) => !disabledFunctions && this.changeDate(e, 'date')}
                      isValidDate={valid}
                      disabled={disabledFunctions}
                    />
                    <DateIconContainer onClick={() => !disabledFunctions && this.showDatePicker('date')} disabled={disabledFunctions}>
                      <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                    </DateIconContainer>
                  </div>
                </InputContainer> :
                <div>
                  <StartDateContainer>
                    <InputContainer disabled={disabledFunctions}>
                      <div ref={(node) => !disabledFunctions && openDateTimePicker === 'date' && this.setDatePickerWrapper(node)}>
                        <StyledDateTime
                          open={openDateTimePicker === 'date'}
                          inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true, disabled:disabledFunctions}}
                          dateFormat="MM/DD/YYYY"
                          closeOnSelect={true}
                          closeOnTab={true}
                          timeFormat={false}
                          value={date}
                          onChange={(e) => !disabledFunctions && this.changeDate(e, 'date')}
                          isValidDate={valid}
                          disabled={disabledFunctions}
                        />
                        <DateIconContainer onClick={() => !disabledFunctions && this.showDatePicker('date')} disabled={disabledFunctions}>
                          <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                        </DateIconContainer>
                      </div>
                    </InputContainer>
                    <InputContainer timeInput disabled={disabledFunctions}>
                      <div ref={(node) => !disabledFunctions && openDateTimePicker === 'time' && this.setDatePickerWrapper(node)}>
                        <StyledDateTime
                          open={openDateTimePicker === 'time'}
                          inputProps={{ placeholder: moment().format('hh:mm a'), readOnly: true, disabled:disabledFunctions}}
                          dateFormat={false}
                          closeOnTab={true}
                          value={time}
                          onChange={(e) => !disabledFunctions && this.changeDate(e, 'time')}
                          disabled={disabledFunctions}
                          timeInput
                        />
                        <i className="far fa-clock" onClick={() => !disabledFunctions && this.showDatePicker('time')} />
                      </div>
                    </InputContainer>
                  </StartDateContainer>
                  <DateJoiner eventPage={1}>to</DateJoiner>
                  <StartDateContainer>
                    {recurringEvent === this.recurringTypes[0] && <InputContainer disabled={disabledFunctions}>
                      <div ref={(node) => !disabledFunctions && openDateTimePicker === 'endDate' && this.setDatePickerWrapper(node)}>
                        <StyledDateTime
                          open={openDateTimePicker === 'endDate'}
                          inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true, disabled:disabledFunctions}}
                          dateFormat="MM/DD/YYYY"
                          closeOnSelect={true}
                          closeOnTab={true}
                          timeFormat={false}
                          value={endDate}
                          onChange={(e) => !disabledFunctions && this.changeDate(e, 'endDate')}
                          isValidDate={valid}
                          disabled={disabledFunctions}
                        />
                        <DateIconContainer onClick={() => !disabledFunctions && this.showDatePicker('endDate')} disabled={disabledFunctions}>
                          <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                        </DateIconContainer>
                      </div>
                    </InputContainer>
                    }
                    <InputContainer timeInput disabled={disabledFunctions}>
                      <div ref={(node) => !disabledFunctions && openDateTimePicker === 'endTime' && this.setDatePickerWrapper(node)}>
                        <StyledDateTime
                          open={openDateTimePicker === 'endTime'}
                          inputProps={{ placeholder: moment().format('hh:mm a'), readOnly: true, disabled:disabledFunctions}}
                          dateFormat={false}
                          closeOnTab={true}
                          value={endTime}
                          onChange={(e) => !disabledFunctions && this.changeDate(e, 'endTime')}
                          disabled={disabledFunctions}
                          timeInput
                        />
                        <i className="far fa-clock" onClick={() => !disabledFunctions && this.showDatePicker('endTime')} />
                      </div>
                    </InputContainer>
                  </StartDateContainer>
                </div>
              }
              {
                showDateTimeInput === 'repeat' &&
                <CustomDropDown
                  title={selectedMenu}
                  id="dropdown-basic"
                  disabled={disabledFunctions}
                >
                  {
                    this.repeatValues.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        onSelect={() => this.onSelect(item)}
                        active={selectedMenu === item}
                      >
                        {item}
                      </CustomMenuItem>
                    ))
                  }
                </CustomDropDown>
              }
            </TitleContainer>
            <TimezoneContainer>
              <FieldTitle>Event Timezone:</FieldTitle>
              <Autocomplete disabled={true} assignedState={'time_zone'} changeHandler={this.onChangeTimeZone} options={timezones.timezones} defaultValue={time_zone}/>
            </TimezoneContainer>
            <TitleContainer>
              <FieldTitle>Email Notifications: Select email option for this Event</FieldTitle>
              <LocationContainer>
                {
                  emailOptionKeys.map((item, index) => (
                    <InviteAmigo key={index}>
                      <CustomCheckbox disabled={disabledEmailOptionStatus}>
                        <input
                          type="checkbox"
                          checked={emailOption[item] === 1}
                          onChange={() => !disabledEmailOptionStatus && this.onChangeEmail(item)}
                        />
                        <CheckMark checked={emailOption[item] === 1} disabled={disabledEmailOptionStatus}/>
                      </CustomCheckbox>
                      <NameContainer onClick={() => !disabledEmailOptionStatus && this.onChangeEmail( item)} disabled={disabledEmailOptionStatus}>
                        <span>Event{item == 'launch_event_email' ? ' Creation' : item == 'event_reminder_email' ? ' Reminder' : ' Completion'} </span>
                      </NameContainer>
                      <div className="showInfoText">{showEmailNotificationInfo[index]}</div>
                    </InviteAmigo>
                  ))
                }
              </LocationContainer>
            </TitleContainer>
            {
              companiesPermission &&
              <AmigosContainer>
                <TopContainer>
                  <FieldTitle>Invite Companies:</FieldTitle>
                  <ButtonContainer>
                    { !disabledFunctions &&
                      <SaveButton color="#159fc9" type="button" onClick={(e) => this.selectAllCompanies(e)}>
                        <i className="fas fa-check"/>
                        {
                          companies.length === allCompanies.length ? 'deselect all companies' : 'select all companies'
                        }
                      </SaveButton>
                    }
                  </ButtonContainer>
                </TopContainer>
                <InviteContainer>
                  <ScrollableContainer>
                    {
                      allCompanies.map((company, index) => (
                        <InviteAmigo key={index}>
                          <CustomCheckbox disabled={disabledFunctions}>
                            <input
                              type="checkbox"
                              checked={companies.includes(company.id)}
                              onChange={() => !disabledFunctions && this.onCheckboxChange(company.id)}
                            />
                            <CheckMark checked={companies.includes(company.id)} disabled={disabledFunctions}/>
                          </CustomCheckbox>
                          <NameContainer onClick={() => !disabledFunctions && this.onCheckboxChange(company.id)} disabled={disabledFunctions}>
                            <span>{company.company_name}</span>
                          </NameContainer>
                        </InviteAmigo>
                      ))
                    }
                  </ScrollableContainer>
                </InviteContainer>
              </AmigosContainer>
            }
            { locationPermission && (com_location && com_location.length > 0 || companyDetails['spouse_or_dependent']) ?
              <AmigosContainer>
                <TopContainer>
                  <FieldTitle> Company Locations:</FieldTitle>
                </TopContainer>
                <InviteContainer>
                  <ScrollableContainer>
                    {<LocationContainer minHeight={com_location && com_location.length}>
                      {
                        locationDetails && locationDetails.length > 0 && locationDetails.map((loc, index) => (
                          <InviteAmigo key={index} hidden={!com_location.includes(loc.id)}>
                            { com_location.includes(loc.id) &&
                            <CustomCheckbox disabled={true}>
                              <input
                                type="checkbox"
                                defaultChecked={com_location.includes(loc.id)}
                              />
                              <CheckMark checked={com_location.includes(loc.id)} disabled={true}/>
                            </CustomCheckbox>
                            }
                            { com_location.includes(loc.id) &&
                            <NameContainer disabled={disabledFunctions}>
                              <span>{loc.location}</span>
                            </NameContainer>
                            }
                          </InviteAmigo>
                        ))
                      }
                    </LocationContainer>
                    }
                    { companyDetails['spouse_or_dependent'] ?
                      <div style={{display: 'flex'}}>
                        <InviteAmigo hidden={!isSpouse}>
                          <CustomCheckbox disabled={true}>
                            <input
                              type="checkbox"
                              checked={isSpouse}
                            />
                            <CheckMark checked={isSpouse} disabled={true}/>
                          </CustomCheckbox>
                          <NameContainer disabled={true}>
                            <span>{'Spouse'}</span>
                          </NameContainer>
                        </InviteAmigo>
                        <InviteAmigo hidden={!isDependent}>
                          <CustomCheckbox disabled={true}>
                            <input
                              type="checkbox"
                              checked={isDependent}
                            />
                            <CheckMark checked={isDependent} disabled={true}/>
                          </CustomCheckbox>
                          <NameContainer disabled={true}>
                            <span>{'Dependent'}</span>
                          </NameContainer>
                        </InviteAmigo>
                        { locationDetails && locationDetails.length == 0 &&
                        <InviteAmigo hidden={!isEmployee}>
                          <CustomCheckbox disabled={true}>
                            <input
                              type="checkbox"
                              checked={isEmployee}
                            />
                            <CheckMark checked={isEmployee} disabled={true}/>
                          </CustomCheckbox>
                          <NameContainer disabled={true}>
                            <span>{'Employee'}</span>
                          </NameContainer>
                        </InviteAmigo>
                        }
                      </div> : null
                    }
                  </ScrollableContainer>
                </InviteContainer>
              </AmigosContainer> : null
            }
            <TitleContainer>
              <FieldTitle>Event Venue:</FieldTitle>
              <RadioButtonContainer>
                <RadioButton id="1" handler={this.toggleRadioButton} value={0} isChecked={selectedOption == 0} label={'At Physical Location'} challengeLeaderBoard={true}/>
                <RadioButton id="2" handler={this.toggleRadioButton} value={1} isChecked={selectedOption == 1} label={'At Virtual Location'} challengeLeaderBoard={true}/>
              </RadioButtonContainer>
            </TitleContainer>
            {
              selectedOption === 0 ? <TitleContainer>
                <FieldTitle>Event Location:</FieldTitle>
                <div>
                  <StyledInput
                    placeholder="Enter Location..."
                    name="location"
                    value={location}
                    onChange={this.onLocationChange}
                    disabled={disabledFunctions}
                    locationInput
                  />
                </div>
                {
                  showPlaces && !!places &&
                            <PlacesContainer>
                              {this.renderPlaces(places)}
                            </PlacesContainer>
                }
              </TitleContainer>
                : <TitleContainer>
                  <FieldTitle>Virtual Event Link:</FieldTitle>
                  <div>
                    <StyledInput
                      placeholder="Enter Event Link..."
                      name="eventLink"
                      onChange={this.onChangeInput}
                      value={eventLink}
                      disabled={disabledFunctions}
                    />
                  </div>
                </TitleContainer>
            }
            <TitleContainer>
              <FieldTitle>Event Description:</FieldTitle>
              <EditorContainer>
                <Editor content={description} onChange={this.onChangeDescription} />
              </EditorContainer>
            </TitleContainer>
            <ImageContainer>
              <SaveButton type="submit" disabled={isDisabled} addMargin>save event</SaveButton>
            </ImageContainer>
          </FormContainer>
          <FeaturedEventsContainer>
            <FeaturedEvents history={history}/>
          </FeaturedEventsContainer>
        </EventContainer>
      </Layout>
    )
  }
}

EditEvent.propTypes = {
  createEvent: PropTypes.func.isRequired,
  getPlacesAction: PropTypes.func.isRequired,
  places: PropTypes.array,
  isLoading: PropTypes.bool,
  history: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  fetchAllCompanies: PropTypes.func.isRequired,
  allCompanies: PropTypes.array,
  fetchCompanyForUser: PropTypes.func.isRequired,
  userCompany: PropTypes.object,
  eventDetail: PropTypes.object,
  EditEventAction: PropTypes.func,
  locationDetails: PropTypes.array,
  companyDetails: PropTypes.object,
  userPermissions: PropTypes.array
};

const mapStateToProps = (state) => ({
  allCompanies: state.challenges.allCompanies,
  userCompany: state.profileData.userCompany,
  eventDetail: state.events.eventDetail,
  locationDetails: state.register.locationDetails,
  companyDetails: state.profileData.companyDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllCompanies: () => dispatch(getAllCompanies()),
  fetchCompanyForUser: () => dispatch(getUserCompanyDetails()),
  EditEventAction: (data) => dispatch(EditEventAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);
